import { string } from 'yup';
import { specialCharacters } from '@lon/shared/constants';
import { getValidationMessages } from './getValidationMessages';

export const getReviewerPasswordValidation = (
  isPreview?: boolean,
  isParent?: boolean
) => {
  let schema = string()
    .required(getValidationMessages().required)
    .matches(/^(?!.* )/, getValidationMessages().noSpaces)
    .min(
      isParent ? 12 : isPreview ? 10 : 16,
      getValidationMessages(isParent ? 12 : isPreview ? 10 : 16).passwordLength
    )
    .matches(/\d+/, getValidationMessages().atLeastOneDigit)
    .matches(/[a-z]+/, getValidationMessages().atLeastOneLowerCase)
    .matches(/[A-Z]+/, getValidationMessages().atLeastOneUpperCase)
    .matches(
      specialCharacters,
      getValidationMessages().atLeastOneSpecialCharacter
    );

  if (isParent) {
    schema = schema.when(['firstName', 'lastName'], {
      is: (firstName: string, lastName: string) =>
        firstName !== '' || lastName !== '',
      then: (schema) =>
        schema.test(
          'password',
          getValidationMessages().noFirstOrLastName,
          function (value) {
            const { firstName, lastName } = this.parent;
            if (!value) {
              return true;
            }

            if (firstName && lastName) {
              return new RegExp(`^((?!${firstName}|${lastName}).)*$`, 'i').test(
                value
              );
            } else if (firstName) {
              return !new RegExp(firstName, 'i').test(value);
            } else {
              return !new RegExp(lastName, 'i').test(value);
            }
          }
        ),
    });
  }

  return schema;
};
