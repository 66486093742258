import { ErrorPage } from '../pages';
import App from '../pages/app';
import {
  ForgotPassword,
  Login,
  LoginContainer,
  Preview,
  QrCode,
  Registration,
  ResetPassword,
  SystemSelect,
} from '../pages/app/components';
import { Text } from '@chakra-ui/react';
import React from 'react';
import { Route, createRoutesFromElements } from 'react-router-dom';
import { routes } from '@lon/login/configs';
import { ApplicationEnum } from '@lon/shared/enums';
import { ErrorBoundary, TestError } from '@lon/shared/pages';
import { UnauthorizedNotFound } from '@lon/shared/pages';

const MainRouter = createRoutesFromElements(
  <Route errorElement={<ErrorBoundary application={ApplicationEnum.LOGIN} />}>
    <Route path={routes.root} element={<App />}>
      <Route element={<LoginContainer />}>
        <Route index element={<Login />} />
        <Route path={routes.login.qrCode} element={<QrCode />} />
        <Route path={routes.login.preview} element={<Preview />} />
        <Route
          path={routes.login.forgotPassword}
          element={<ForgotPassword />}
        />
      </Route>
      <Route path={routes.login.passwordReset} element={<ResetPassword />} />
      <Route
        path={routes.login.registration.adoption}
        element={<Registration.AdoptionRegistraton />}
      />
      <Route
        path={routes.login.registration.parent}
        element={<Registration.ParentRegistraton />}
      />
      <Route
        path={routes.login.registration.preview}
        element={<Registration.PreviewRegistration />}
      />
      <Route path="/login/test-error" element={<TestError />} />
    </Route>
    <Route path={routes.login.systemSelect} element={<SystemSelect />} />
    <Route
      path={routes.login.systemSelectLti}
      element={<SystemSelect isLti />}
    />
    <Route
      path={routes.login.stemOld}
      element={<Text variant="h1">UNDER CONSTRUCTION</Text>}
    />
    <Route path={routes.login.error} element={<ErrorPage />} />
    <Route path="*" element={<UnauthorizedNotFound />} />
  </Route>
);

export default MainRouter;
