import { t } from 'i18next';
import { ToastFn } from '@lon/shared/types';

export const showCopyLinkNotification = (toast: ToastFn) => {
  toast({
    title: t('common.linkCopied') as string,
    variant: 'success-light',
    duration: 2000,
    isClosable: true,
  });
};
