export default {
  home: '/',
  root: () => '/admin',
  help: {
    root: '/help/:articleId',
    details: '/help/details/:id',
  },
  districtManagement: {
    root: '/district-management',
    schoolInformation: '/district-management/school-information',
    districtSettings: '/district-management/district-settings',
  },
  integrations: {
    root: '/integrations',
    management: '/integrations-management',
    rostering: {
      root: '/integrations/rostering',
      oneRoster: {
        create: {
          root: '/integrations/rostering/one-roster/create',
          zipUpload: '/integrations/rostering/one-roster/create/zip-upload',
          sftp: '/integrations/rostering/one-roster/create/sftp',
          schoolsMapping:
            '/integrations/rostering/one-roster/create/schools-mapping',
          rolesMapping:
            '/integrations/rostering/one-roster/create/roles-mapping',
        },
        edit: {
          root: '/integrations/rostering/one-roster/edit',
          zipUpload: '/integrations/rostering/one-roster/edit/zip-upload',
          sftp: '/integrations/rostering/one-roster/edit/sftp',
          schoolsMapping:
            '/integrations/rostering/one-roster/edit/schools-mapping',
          rolesMapping: '/integrations/rostering/one-roster/edit/roles-mapping',
          logs: '/integrations/rostering/one-roster/edit/log',
          settings: '/integrations/rostering/one-roster/edit/settings',
        },
      },
      clever: {
        create: {
          root: '/integrations/rostering/clever/create',
          credentials: '/integrations/rostering/clever/create/credentials',
          schoolsMapping:
            '/integrations/rostering/clever/create/schools-mapping',
          rolesMapping: '/integrations/rostering/clever/create/roles-mapping',
        },
        edit: {
          root: '/integrations/rostering/clever/edit',
          credentials: '/integrations/rostering/clever/edit/credentials',
          schoolsMapping: '/integrations/rostering/clever/edit/schools-mapping',
          rolesMapping: '/integrations/rostering/clever/edit/roles-mapping',
          logs: '/integrations/rostering/clever/edit/log',
          settings: '/integrations/rostering/clever/edit/settings',
        },
      },
      classLink: {
        create: {
          root: '/integrations/rostering/class-link/create',
          credentials: '/integrations/rostering/class-link/create/credentials',
          schoolsMapping:
            '/integrations/rostering/class-link/create/schools-mapping',
          rolesMapping:
            '/integrations/rostering/class-link/create/roles-mapping',
        },
        edit: {
          root: '/integrations/rostering/class-link/edit',
          credentials: '/integrations/rostering/class-link/edit/credentials',
          schoolsMapping:
            '/integrations/rostering/class-link/edit/schools-mapping',
          rolesMapping: '/integrations/rostering/class-link/edit/roles-mapping',
          logs: '/integrations/rostering/class-link/edit/log',
          settings: '/integrations/rostering/class-link/edit/settings',
        },
      },
      edfi: {
        create: {
          root: '/integrations/rostering/ed-fi/create',
          credentials: '/integrations/rostering/ed-fi/create/credentials',
          schoolsMapping:
            '/integrations/rostering/ed-fi/create/schools-mapping',
          rolesMapping: '/integrations/rostering/ed-fi/create/roles-mapping',
          gradesMapping: '/integrations/rostering/ed-fi/create/grades-mapping',
        },
        edit: {
          root: '/integrations/rostering/ed-fi/edit',
          credentials: '/integrations/rostering/ed-fi/edit/credentials',
          schoolsMapping: '/integrations/rostering/ed-fi/edit/schools-mapping',
          rolesMapping: '/integrations/rostering/ed-fi/edit/roles-mapping',
          logs: '/integrations/rostering/ed-fi/edit/log',
          settings: '/integrations/rostering/ed-fi/edit/settings',
          gradesMapping: '/integrations/rostering/ed-fi/edit/grades-mapping',
        },
      },
      skyward: {
        create: {
          root: '/integrations/rostering/skyward/create',
          credentials: '/integrations/rostering/skyward/create/credentials',
          schoolsMapping:
            '/integrations/rostering/skyward/create/schools-mapping',
          rolesMapping: '/integrations/rostering/skyward/create/roles-mapping',
        },
        edit: {
          root: '/integrations/rostering/skyward/edit',
          credentials: '/integrations/rostering/skyward/edit/credentials',
          schoolsMapping:
            '/integrations/rostering/skyward/edit/schools-mapping',
          rolesMapping: '/integrations/rostering/skyward/edit/roles-mapping',
          logs: '/integrations/rostering/skyward/edit/log',
          settings: '/integrations/rostering/skyward/edit/settings',
        },
      },
      importResults: {
        root: '/integrations/rostering/import-results',
        viewErrors: '/integrations/rostering/import-results/view-errors/:id',
      },
    },
    sso: {
      root: '/integrations/sso',
      clever: {
        create: '/integrations/sso/clever/create',
        edit: '/integrations/sso/clever/edit',
      },
      classLink: {
        create: '/integrations/sso/class-link/create',
        edit: '/integrations/sso/class-link/edit',
      },
      microsoftSaml: {
        create: '/integrations/sso/microsoft-saml/create',
        edit: '/integrations/sso/microsoft-saml/edit',
      },
      googleSaml: {
        create: '/integrations/sso/google-saml/create',
        edit: '/integrations/sso/google-saml/edit',
      },
      canvas: {
        create: '/integrations/sso/canvas/create',
        edit: '/integrations/sso/canvas/edit',
      },
      canvasOauth2: {
        create: '/integrations/sso/canvas-oauth2/create',
        edit: '/integrations/sso/canvas-oauth2/edit',
      },
      classLinkSaml: {
        create: '/integrations/sso/class-link-saml/create',
        edit: '/integrations/sso/class-link-saml/edit',
      },
    },
    lmsLti: {
      root: '/integrations/lms-lti',
      canvas: {
        create: '/integrations/lms-lti/canvas/create',
        edit: '/integrations/lms-lti/canvas/edit',
      },
      canvasTcc: {
        root: '/integrations/lms-lti/canvas-tcc',
      },
      schoologyTcc: {
        root: '/integrations/lms-lti/schoology-tcc',
      },
      schoology: {
        create: '/integrations/lms-lti/schoology/create',
        edit: '/integrations/lms-lti/schoology/edit',
      },
      safari: {
        create: '/integrations/lms-lti/safari-montage/create',
        edit: '/integrations/lms-lti/safari-montage/edit',
      },
    },
  },
  userManagement: {
    root: '/user-management',
    users: {
      root: '/user-management/users',
      createStudent: '/user-management/users/create/student',
      createStaff: '/user-management/users/create/staff',
      createAdmin: '/user-management/users/create/admin',
      edit: '/user-management/users/edit/:userId',
      students: '/user-management/users/students',
      staff: '/user-management/users/staff',
      upload: '/user-management/users/upload',
    },
    userRoles: {
      root: '/user-management/user-roles',
    },
  },
  reports: {
    root: '/reports',
    adminReport: '/reports/report',
  },
  subscriptions: {
    root: 'subscriptions',
    viewLicenses: '/subscriptions/view-licenses',
  },
  wizard: {
    root: 'wizard/review',
    welcome: '/wizard/welcome',
  },
};
