import { useLazyQuery, useMutation } from '@apollo/client';
import parse from 'graphql-tag';
import * as React from 'react';
import {
  useGetSchoolsLazyQuery,
  useGetUserRolesLazyQuery,
} from '@lon/shared/requests';

export const useDocumentNode = () => {
  const documentNodeFindUser = React.useMemo(() => {
    const queryParsers: String[] = [];
    const queryIndex: String[] = [];
    for (let index = 0; index <= 100; index++) {
      queryParsers.push(`user${index}:users(username: $username${index}, districtId: $districtId${index} )  @include(if: $inputBoolean${index}) {
        collection {
          _id
          id
          username
        }
      }
      `);
      queryIndex.push(
        `$username${index}: String, $districtId${index}: String, $inputBoolean${index}: Boolean! `
      );
    }

    return parse(
      'query getUsers(' +
        queryIndex.join(',') +
        ') { \n ' +
        queryParsers.join('\n') +
        ' } '
    );
  }, []);
  const documentNodeCreateUser = React.useMemo(() => {
    const queryParsers: String[] = [];
    const queryIndex: String[] = [];
    for (let index = 0; index <= 100; index++) {
      queryParsers.push(`a${index}:createUser(input: $input${index})  @include(if: $inputBoolean${index}) {
        user {
          _id
          firstName
          lastName
          id
        }
        clientMutationId
      }
  `);
      queryIndex.push(
        `$input${index}: createUserInput!, $inputBoolean${index}: Boolean! `
      );
    }

    return parse(
      'mutation CreateUser(' +
        queryIndex.join(',') +
        ') { \n ' +
        queryParsers.join('\n') +
        ' } '
    );
  }, []);
  const documentNodeUpdateUser = React.useMemo(() => {
    const queryParsers: String[] = [];
    const queryIndex: String[] = [];
    for (let index = 0; index <= 100; index++) {
      queryParsers.push(`a${index}:updateUser(input: $input${index})  @include(if: $inputBoolean${index}) {
        user {
          _id
          firstName
          lastName
          id
        }
        clientMutationId
      }
  `);
      queryIndex.push(
        `$input${index}: updateUserInput!, $inputBoolean${index}: Boolean! `
      );
    }

    return parse(
      'mutation updateUser(' +
        queryIndex.join(',') +
        ') { \n ' +
        queryParsers.join('\n') +
        ' } '
    );
  }, []);

  const documentNodeFindClass = React.useMemo(() => {
    const queryParsers: String[] = [];
    const queryIndex: String[] = [];
    for (let index = 0; index <= 100; index++) {
      queryParsers.push(`klass${index}:classes(filter: { name: $name${index}, schoolId: $schoolId${index}, teachersId: $teachersId${index} } )  @include(if: $inputBoolean${index}) {
        collection {
          _id
          id
          name
          classStudents(page: 1, itemsPerPage: 1000) {
          collection {
            student {
              id
              _id
            }
          }
        }
        }
      }
      `);
      queryIndex.push(
        `$name${index}: String, $schoolId${index}: String, $teachersId${index}: String, $inputBoolean${index}: Boolean! `
      );
    }

    return parse(
      'query getClass(' +
        queryIndex.join(',') +
        ') { \n ' +
        queryParsers.join('\n') +
        ' } '
    );
  }, []);
  const documentNodeCreateClass = React.useMemo(() => {
    const queryParsers: String[] = [];
    const queryIndex: String[] = [];
    for (let index = 0; index <= 100; index++) {
      queryParsers.push(`klass${index}:createClass(input: $input${index})  @include(if: $inputBoolean${index}) {
        class {
          _id
          __typename
          id
        }
      }
  `);
      queryIndex.push(
        `$input${index}: createClassInput!, $inputBoolean${index}: Boolean! `
      );
    }

    return parse(
      'mutation createClass(' +
        queryIndex.join(',') +
        ') { \n ' +
        queryParsers.join('\n') +
        ' } '
    );
  }, []);

  const documentNodeAddStudentClass = React.useMemo(() => {
    const queryParsers: String[] = [];
    const queryIndex: String[] = [];
    for (let index = 0; index <= 100; index++) {
      queryParsers.push(`klass${index}:addStudentsToClass(input: $input${index})  @include(if: $inputBoolean${index}) {
        class {
          id
          __typename
        }
        __typename
      }
  `);
      queryIndex.push(
        `$input${index}: addStudentsToClassInput!, $inputBoolean${index}: Boolean! `
      );
    }

    return parse(
      'mutation addStudentsToClass(' +
        queryIndex.join(',') +
        ') { \n ' +
        queryParsers.join('\n') +
        ' } '
    );
  }, []);

  const [getSchools] = useGetSchoolsLazyQuery();
  const [roles] = useGetUserRolesLazyQuery();

  const [getUsersByUserName] = useLazyQuery(documentNodeFindUser);
  const [createUser] = useMutation(documentNodeCreateUser);
  const [updateUser] = useMutation(documentNodeUpdateUser);

  const [getClassByName] = useLazyQuery(documentNodeFindClass);
  const [createClass] = useMutation(documentNodeCreateClass);

  const [addStudentsToClass] = useMutation(documentNodeAddStudentClass);

  return {
    getSchools,
    roles,
    getUsersByUserName,
    createUser,
    updateUser,
    getClassByName,
    createClass,
    addStudentsToClass,
  };
};
