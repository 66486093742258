import { createBrowserRouter } from 'react-router-dom';
import { isReviewSite } from '@lon/shared/constants';
import MainRouter from './MainRouter';
import ReviewSiteRouter from './ReviewSiteRouter';

const getCurrentRouter = () => {
  let router = MainRouter;

  if (isReviewSite()) {
    router = ReviewSiteRouter;
  }
  return router;
};

const useCurrentRouter = () => {
  return createBrowserRouter(getCurrentRouter());
};

export default useCurrentRouter;
